<script setup lang="ts">
import Checkbox from '@/Components/Checkbox.vue';
import DemergoLayout from '@/Layouts/DemergoLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

defineProps<{
    canResetPassword?: boolean;
    status?: string;
}>();

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.post(route('login'), {
        onFinish: () => {
            form.reset('password');
        },
    });
};
</script>

<template>
    <DemergoLayout>
        <Head title="Demergo | Demenz & Ergotherapie - Anmelden" />

        <div class="auth__body flex flex-col sm:justify-center items-center pt-6 sm:pt-0">
            <div
                class="w-full sm:max-w-md px-6 py-4 bg-gray-100 shadow-md overflow-hidden sm:rounded-lg"
            >
                <div v-if="status" class="mb-4 font-medium text-sm text-lime-600">
                    {{ status }}
                </div>

                <form @submit.prevent="submit">
                    <div>
                        <InputLabel for="email" value="E-Mail-Adresse" />

                        <TextInput
                            id="email"
                            type="email"
                            class="mt-1 block w-full"
                            v-model="form.email"
                            required
                            autofocus
                            autocomplete="email"
                        />

                        <InputError class="mt-2" :message="form.errors.email" />
                    </div>

                    <div class="mt-4">
                        <InputLabel for="password" value="Passwort" />

                        <TextInput
                            id="password"
                            type="password"
                            class="mt-1 block w-full"
                            v-model="form.password"
                            required
                            autocomplete="current-password"
                        />

                        <InputError class="mt-2" :message="form.errors.password" />
                    </div>

                    <div class="block mt-4">
                        <label class="flex items-center">
                            <Checkbox name="remember" v-model:checked="form.remember" />
                            <span class="ms-2 text-sm text-gray-600">Angemeldet bleiben</span>
                        </label>
                    </div>

                    <div class="flex items-center justify-end mt-4">
                        <Link
                            v-if="canResetPassword"
                            :href="route('password.request')"
                            class="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                        >
                            Passwort vergessen?
                        </Link>

                        <PrimaryButton class="ms-4 btn--cta" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                            Anmelden
                        </PrimaryButton>
                    </div>
                </form>

                <div class="flex flex-col items-center justify-center mt-8">
                    <hr class="bg-gray-100 h-1 w-full mb-4">
                    <p class="text-sm mb-2">Sie haben die Demergo-Fortbildung erfolgreich abgeschlossen und möchten in das Therapeutenverzeichnis mitaufgenommen werden? Dann klicken Sie bitte auf den nachfolgenden Button und füllen das Aufnahmeformular aus:</p>
                    <a
                        :href="route('demergo.admission_form')"
                        class="inline-flex items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-lime-700 focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2 transition ease-in-out duration-150 btn--cta">
                        Aufnahmeformular ausfüllen
                    </a>
                </div>
            </div>
        </div>
        
    </DemergoLayout>
</template>
